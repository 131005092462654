<template>
  <div id="preview">
    <v-card>
      <v-form ref="form1" v-model="valid1" lazy-validation>
        <v-card-title>
          <span class="headline">{{ pageTitle }}</span>
        </v-card-title>
        <v-card-subtitle>
          <span class="headline"> {{ pageDescription }} </span>
        </v-card-subtitle>
        <v-card-text>
          <small>*indicates required field</small>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label>*Year</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="JciYearCodeOptions"
                  :rules="JciYearCodeRules"
                  :loading="JciYearCodeOptionsLoading"
                  v-model="JciYearCode"
                  hint="Select Year"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>*Contribution Type</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="ContributionTypeIdOptions"
                  :rules="ContributionTypeIdRules"
                  :loading="ContributionTypeIdOptionsLoading"
                  v-model="ContributionTypeId"
                  hint="Select Contribution Type"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>Member Type</label>
                <h4>{{ MemberTypeName }}</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label>* Start Date</label>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  required
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="StartDate"
                      readonly
                      v-on="on"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="StartDate"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>* End Date</label>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  required
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="EndDate"
                      readonly
                      v-on="on"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="EndDate"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>* Voting Permission</label>
                <v-switch
                  v-model="VotingFlag"
                  :label="`${VotingFlag ? 'Yes' : 'No'}`"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label>* Amount</label>
                <v-text-field
                  v-on="calculateTotal()"
                  v-model="Amount"
                  :rules="AmountRules"
                  hint="Amount"
                  type="number"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>* Tax Percentage</label>
                <v-text-field
                  v-on="calculateTotal()"
                  v-model="TaxPercentage"
                  :rules="TaxPercentageRules"
                  hint="TaxPercentage"
                  type="number"
                  min="0"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>* Final Amount</label>
                <h3>{{ FinalAmount }}</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label>* Welfare Amount</label>
                <v-text-field
                  v-model="WelfareAmount"
                  :rules="WelfareAmountRules"
                  hint="Welfare Amount"
                  type="number"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>* No. of times</label>
                <v-text-field
                  v-model="NoOfTimes"
                  :rules="NoOfTimesRules"
                  hint="No if times"
                  type="number"
                  min="0"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>* Fine</label>
                <v-switch
                  v-model="FineFlag"
                  :label="`${FineFlag ? 'Yes' : 'No'}`"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="FineFlag">
                <label>* Fine Amount</label>
                <v-text-field
                  v-model="FineAmount"
                  :rules="FineAmountRules"
                  hint="Fine Amount"
                  type="text"
                  min="0"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="FineFlag">
                <label>* Fine End Date</label>
                <v-menu
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  required
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="FineEndDate"
                      readonly
                      v-on="on"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="FineEndDate"
                    @input="menu3 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>* Is payment allowed after fine deadline? </label>
                <v-switch
                  v-model="DeadlineFlag"
                  :label="`${DeadlineFlag ? 'Yes' : 'No'}`"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>* Active Status</label>
                <v-switch
                  v-model="ActiveStatus"
                  :label="`${ActiveStatus ? 'Active' : 'Inactive'}`"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="blue darken-1" text @click="closePrompt">Close</v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="SubmitFlag"
            :disabled="!valid1"
            @click="confirmAlert"
            >Save</v-btn
          > -->
          <v-btn
            @click="closePrompt"
            elevation="30"
            shaped
            tile
            small
            color="#a52a2a"
            class="font-size-h6 px-10 mr-3 white--text"
          >
            Close
          </v-btn>
          <v-btn
            :disabled="!valid1"
            @click.prevent="confirmAlert"
            :loading="SubmitFlag"
            elevation="30"
            shaped
            tile
            small
            color="#1db954"
            class="font-size-h6 px-10 mr-3 white--text"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    editRecordPrompt: {
      type: Boolean,
      required: true,
    },
    pageTitle: {
      type: String,
    },
    pageDescription: {
      type: String,
    },
    recordIndex: {
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      rows: [],

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: "",
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      ContributionTypeIdRules: [(v) => !!v || "Contribution Type is required"],
      ContributionTypeId: "",
      ContributionTypeIdOptions: [],
      ContributionTypeIdOptionsLoading: false,

      MemberTypeName: "",

      VotingFlag: 0,
      VotingFlagRules: [(v) => !!v || "Voting Permission is required"],

      StartDate: "",
      menu1: false,

      EndDate: "",
      menu2: false,

      Amount: "",
      AmountRules: [(v) => !!v || "Amount is required"],

      WelfareAmount: "",
      WelfareAmountRules: [
        // (v) => !!v || "Amount is required"
      ],

      TaxPercentage: "",
      TaxPercentageRules: [
        // (v) => !!v || "Tax Percentage is required"
      ],

      FinalAmount: 0,

      FineFlag: 0,
      FineFlagRules: [(v) => !!v || "Fine is required"],

      DeadlineFlag: 0,
      DeadlineFlagRules: [(v) => !!v || "Is allow deadline is required"],

      ActiveStatus: 0,
      ActiveStatusRules: [(v) => !!v || "Active Status is required"],

      FineAmount: "",
      FineAmountRules: [
        // (v) => !!v || "Fine Amount is required"
      ],

      FineEndDate: "",
      menu3: false,

      NoOfTimes: "",
      NoOfTimesRules: [
        // (v) => (v && v <= 0) || "No of times should be above or equal to 0",
      ],
    };
  },
  computed: {},
  watch: {
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.JciYearCodeOptionsLoading = false;
    },
    ContributionTypeIdOptions: function () {
      console.log("watch ContributionTypeIdOptions");
      this.ContributionTypeIdOptionsLoading = false;
      this.getMemberType();
    },
    ContributionTypeId: function () {
      console.log("watch ContributionTypeId");
      this.getMemberType();
    },
    rows: function () {
      console.log("watch rows");
      var tr = this.rows;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.JciYearCode = tr.YearId;
        this.ContributionTypeId = tr.ContributionTypeId;
        this.StartDate = tr.StartDate;
        this.EndDate = tr.EndDate;
        this.Amount = tr.ContributionAmount;
        this.WelfareAmount = tr.WelfareAmount;
        this.TaxPercentage = tr.Tax;
        this.NoOfTimes = tr.ContributionCount;
        this.VotingFlag = tr.VotingFlag;
        this.FineFlag = tr.FineFlag;
        this.FineAmount = tr.FineAmount;
        this.FineEndDate = tr.FineEndDate;
        this.DeadlineFlag = tr.DeadlineFlag;
        this.ActiveStatus = tr.ActiveStatus;
        this.getMemberType();
      }
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      //   this.$refs.ProfilePicture.reset();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideEditRecordPrompt", this.ResultFlag);
    },
    refreshPageData() {
      console.log("refreshPageData called");
      var recordIndex = this.recordIndex;
      console.log("recordIndex=" + recordIndex);
      this.getRecordDetails();
      this.getJciYearCodeOptions();
      this.getContributionTypeIdOptions();
    },
    getJciYearCodeOptions() {
      this.LoadingFlag = true;
      this.JciYearCodeOptionsLoading = true;
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getContributionTypeIdOptions() {
      this.LoadingFlag = true;
      this.ContributionTypeIdOptionsLoading = true;
      console.log("ContributionTypeIdOptions called");
      var selectbox1_source = "ContributionTypeId";
      var selectbox1_destination = "ContributionTypeIdOptions";
      var selectbox1_url = "api/contribution-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    calculateTotal() {
      console.log("calculateTotal called");
      var Amount = this.Amount;
      var TaxPercentage = this.TaxPercentage;
      Amount = !isNaN(Amount) ? (Amount >= 0 ? Amount : 0) : 0;
      TaxPercentage =
        !isNaN(TaxPercentage) && TaxPercentage >= 0 ? TaxPercentage : 0;
      console.log("Amount=" + Amount + ", TaxPercentage=" + TaxPercentage);
      var TaxAmount = parseFloat(Amount) * (parseFloat(TaxPercentage) / 100);
      console.log("TaxAmount=" + TaxAmount);
      var TotalAmount = parseFloat(Amount) + parseFloat(TaxAmount);
      console.log("TotalAmount=" + TotalAmount);
      this.FinalAmount = isNaN(TotalAmount) ? 0 : TotalAmount;
    },
    getMemberType() {
      console.log("getMemberType called");
      var searchValue1 = this.ContributionTypeId;
      var index1 = this.ContributionTypeIdOptions.map((e) => e.value).indexOf(
        searchValue1
      );
      console.log("searchValue1=" + searchValue1 + ", index1=" + index1);
      var MemberTypeName =
        this.ContributionTypeIdOptions[index1].MemberTypeName;
      console.log("MemberTypeName=" + MemberTypeName);
      this.MemberTypeName = MemberTypeName;
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        this.toast("error", "Kindly fill the required fields");
      }
    },
    getRecordDetails() {
      // add todo in store and clear all fields in dialog
      console.log("getRecordDetails called");

      //   this.resetMessageTxt();

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      console.log("server_url=" + server_url + ", token=" + token);

      var recordIndex = this.recordIndex;

      console.log("recordIndex=" + recordIndex);

      if (recordIndex != "" && recordIndex != (null || undefined)) {
        var upload = {
          UserInterface: 1,
          YearwiseContributionId: recordIndex,
        };
        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var flag = 0;
        var records = [];

        // thisIns.pageLoadingOn()
        thisIns.ProgessStart = 1;
        thisIns.valid = false;

        var CancelToken = this.CancelToken;
        var cancel;

        var add_url = server_url + "api/year-wise-contribution/show";

        console.log("add_url=" + add_url);

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
          }),
        })
          .then(function (response) {
            // thisIns.pageLoadingOff()
            thisIns.ProgessStart = 0;
            thisIns.valid = true;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.rows = records;
              this.toast("success", output);
            } else {
              this.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            thisIns.ProgessStart = 0;
            thisIns.valid = true;
            console.log({ error });
          });
      } else {
        console.log("some errors occured");
        var message = "Year wise contribution id should not be empty";
        this.toast("error", message);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      //   validate1 = false;

      if (validate1) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-contribution/update";
        var upload = {
          YearwiseContributionId: this.recordIndex,
          YearId: this.JciYearCode,
          ContributionTypeId: this.ContributionTypeId,
          StartDate: this.StartDate,
          EndDate: this.EndDate,
          Amount: this.Amount,
          WelfareAmount: this.WelfareAmount,
          TaxPercentage: this.TaxPercentage,
          FineFlag: this.FineFlag,
          DeadlineFlag: this.DeadlineFlag,
          FineAmount: this.FineAmount,
          FineEndDate: this.FineEndDate,
          VotingFlag: this.VotingFlag,
          NoOfTimes: this.NoOfTimes,
          ActiveStatus: this.ActiveStatus,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.ResultFlag = true;
              thisIns.sweetAlert("success", output, true);
              thisIns.resetForm();
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        thisIns.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
