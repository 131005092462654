<template>
  <div id="subscription-list">
    <v-container class="py-0">
      <v-speed-dial
        v-model="fab"
        :top="top"
        :bottom="bottom"
        :right="right"
        :left="left"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
        :fixed="true"
      >
        <!-- <v-btn fab dark small color="green">
          <v-icon>mdi-pencil</v-icon>
        </v-btn> -->
        <!-- <v-btn fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->
      </v-speed-dial>
    </v-container>
    <v-dialog v-model="addRecordPrompt" persistent max-width="600px">
      <subscription-type-create
        pageTitle="Create Subcription Type"
        pageDescription="Add a new Subcription Type"
        :addRecordPrompt="addRecordPrompt"
        @hideAddRecordPrompt="hideAddRecordPrompt"
        v-if="addRecordPrompt"
      ></subscription-type-create>
    </v-dialog>
    <v-dialog v-model="editRecordPrompt" persistent max-width="600px">
      <subscription-type-edit
        pageTitle="Edit Subcription Type"
        pageDescription="Edit existing subcription type details"
        :editRecordPrompt="editRecordPrompt"
        @hideEditRecordPrompt="hideEditRecordPrompt"
        :recordIndex="recordToEdit"
        v-if="editRecordPrompt"
      ></subscription-type-edit>
    </v-dialog>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>
          {{ PageDescription }}
        </h4>
      </v-card-subtitle>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container v-if="StartupLoadingFlag">
          <v-row wrap>
            <v-col cols="12" md="4" v-for="n in 6" :key="n">
              <v-skeleton-loader
                v-bind="attrs"
                type="paragraph"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="!StartupLoadingFlag">
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="12" md="3">
                <label>*Year</label>
                <v-autocomplete
                  @change="getTableRecords"
                  :reduce="(option) => option.value"
                  :items="JciYearCodeOptions"
                  :rules="JciYearCodeRules"
                  :loading="JciYearCodeOptionsLoading"
                  v-model="JciYearCode"
                  hint="Select Year"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  @click.prevent="addRecordPrompt = true"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  Create
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="SearchFlag && !StartupLoadingFlag">
          <v-skeleton-loader
            v-bind="attrs"
            type="table-tbody"
          ></v-skeleton-loader>
        </v-container>
        <v-container
          class="py-0"
          v-if="!SearchFlag && !StartupLoadingFlag && rows.length == 0"
        >
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h4>No records found.</h4>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="!SearchFlag && rows.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3 class="text-primary">{{ rows.length }} records found.</h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :headers="tableColumns1"
                :items="rows"
                :items-per-page="50"
                :search="search"
                item-key="MemberId"
                :single-select="false"
                :show-select="TableSelectFlag"
                :footer-props="{
                  'items-per-page-options': [10, 20, 30, 40, 50],
                }"
              >
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                    >{{ item.ActiveStatusTxt }}</v-chip
                  >
                </template>
                <template v-slot:item.ProfilePic="{ item }">
                  <img width="100" height="100" :src="item.ProfilePic" />
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        icon
                        size="25px"
                        color="blue"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                        v-if="item.ActiveStatus == 2"
                      >
                        mdi-clipboard-text-search
                      </v-icon>
                    </template>
                    <span> Preview </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="warning"
                        @click="editAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-document-edit
                      </v-icon>
                    </template>
                    <span> Edit </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import SubscriptionTypeCreate from "@/view/pages/erp/subscription-type/SubscriptionTypeCreate.vue";
import SubscriptionTypeEdit from "@/view/pages/erp/subscription-type/SubscriptionTypeEdit.vue";

export default {
  mixins: [common],
  components: {
    SubscriptionTypeCreate,
    SubscriptionTypeEdit,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,
      TableSelectFlag: false,

      rows: [],
      tableColumns1: [],
      selected: [],
      search: "",

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      zone: {},
      lom: {},

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      name: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      select: "Item 1",
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      checkbox: false,

      FromZoneCodeRules: [(v) => !!v || "Zone is required"],
      FromZoneCode: "",
      FromZoneCodeOptions: [],
      FromZoneCodeOptionsLoading: false,

      FromLomCodeRules: [(v) => !!v || "LOM is required"],
      FromLomCode: "",
      FromLomCodeOptions: [],
      FromLomCodeOptionsLoading: false,

      ToZoneCodeRules: [(v) => !!v || "Zone is required"],
      ToZoneCode: "",
      ToZoneCodeOptions: [],
      ToZoneCodeOptionsLoading: false,

      ToLomCodeRules: [(v) => !!v || "LOM is required"],
      ToLomCode: "",
      ToLomCodeOptions: [],
      ToLomCodeOptionsLoading: false,

      SearchId: "",
      SearchIdRules: [(v) => !!v || "Search Type is required"],
      SearchIdOptions: [
        { value: "", text: "--Select Type--" },
        { value: 1, text: "By LOM" },
        { value: 2, text: "By Membership Id" },
      ],

      MembershipId: "",
      MembershipIdRules: [(v) => !!v || "Membership Id is required"],

      YearHalfId: "",
      YearHalfIdRules: [(v) => !!v || "Year Half is required"],
      YearHalfIdOptions: [
        { value: "", text: "--Select Half--" },
        { value: "First", text: "First Half" },
        { value: "Second", text: "Second Half" },
      ],

      YearIdRules: [(v) => !!v || "Year is required"],
      YearId: "",
      YearIdOptions: [],
      YearIdOptionsLoading: false,

      ApprovalDescription: "",
      ApprovalDescriptionRules: [],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        var ZoneCode = this.$session.get("ZoneId");
        ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
        console.log("ZoneCode=" + ZoneCode);
        this.ZoneCode = ZoneCode;

        this.pageData();
      }
    },
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.StartupLoadingFlag = false;
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
      this.getTableRecords();
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "membership_subscriptions",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.StartupLoadingFlag = true;
      this.getJciYearCodeOptions();
    },
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    hideAddRecordPrompt(flag) {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      console.log({ flag });
      if (flag) {
        this.getTableRecords();
      }
    },
    hideEditRecordPrompt(flag) {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      console.log({ flag });
      if (flag) {
        this.getTableRecords();
      }
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    editAlert(tr) {
      console.log("editAlert called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        // var CityId = tr.CityId;
        // this.member = tr;
        this.recordToEdit = tr.YearwiseContributionId;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one record to edit";
        this.errorMessage(message);
      }
    },
    getJciYearCodeOptions() {
      this.LoadingFlag = true;
      this.JciYearCodeOptionsLoading = true;
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var YearCode = this.JciYearCode;
      console.log("YearCode=" + YearCode);

      this.rows = [];

      if (YearCode != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-contribution/lists";
        var upload = {
          UserInterface: 2,
          YearCode: YearCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.rows = [];
        this.SearchFlag = true;
        this.LoadingFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SearchFlag = false;
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.rows = records.TableData;
              thisIns.TableSelectFlag = records.TableSelectFlag;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.SearchFlag = false;
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Year should not be empty.";
        console.log("error=" + message);
      }
    },
    getZoneCodeOptions(source, destination) {
      console.log("getZoneCodeOptions called");
      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      var CurrentYearId = this.CurrentYearId;
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", CurrentYearId=" +
          CurrentYearId
      );
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: CurrentYearId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getLomCodeOptions(source, destination, ZoneCode) {
      console.log("getLomCodeOptions called");
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", ZoneCode=" +
          ZoneCode
      );
      var CurrentYearId = this.CurrentYearId;
      if (ZoneCode != "" && CurrentYearId != "") {
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: CurrentYearId,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "Zone should not be empty";
        this.toast("error", message);
      }
    },
    getYearCodeOptions(source, destination) {
      console.log("getYearCodeOptions called");
      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      // var CurrentYearId = this.CurrentYearId;
      var CurrentYearId = 1;
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", CurrentYearId=" +
          CurrentYearId
      );
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/jci-year/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form2.validate();
      var SearchId = this.SearchId;
      var UserInterface = SearchId == 1 ? 1 : 3;
      console.log(
        "validate1=" +
          validate1 +
          ", SearchId=" +
          SearchId +
          ", UserInterface=" +
          UserInterface
      );

      if (validate1) {
        this.SearchFlag = true;
        this.rows = [];
        this.zone = {};
        this.lom = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members";
        var upload = {
          UserInterface: UserInterface,
          additional: {
            MembershipId: this.MembershipId,
            LomCode: this.FromZoneCode,
            LomCode: this.FromLomCode,
          },
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.rows = records;
              thisIns.zone = response.data.zone;
              thisIns.lom = response.data.lom;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form2.validate();
      var validate2 = this.$refs.form3.validate();
      console.log("validate1=" + validate1 + "validate2=" + validate2);

      var members = this.selected;
      var n1 = members.length;
      console.log("n1=" + n1 + ", members=" + JSON.stringify(members));

      // this.rows = [];

      if (validate1 && validate2 && n1 > 0) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/transfer";
        var upload = {
          UserInterface: this.SearchId,
          FromZoneCode: this.FromZoneCode,
          FromLomCode: this.FromLomCode,
          MembershipId: this.MembershipId,
          ToZoneCode: this.ToZoneCode,
          ToLomCode: this.ToLomCode,
          YearId: this.YearId,
          YearHalf: this.YearHalfId,
          ApprovalDescription: this.ApprovalDescription,
          Members: members,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.resetForm();
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1 || !validate2) {
          message += "Kindly fill the required fields";
        }
        if (n1 == 0) {
          message += "Atleast one member is required to transfer";
        }
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#subscription-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 16px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 15px;
  }
}
</style>